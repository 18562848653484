@import "./mediaquery";
@import "./variables.scss";

.toolbuilder {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .title {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 10px;
    margin-bottom: 1em;

    .add {
      font-size: small;
      padding: 5px 10px 5px 10px;
      margin-right: auto;
      display: block;
      color: $primarylight;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      background-color: white;
    }

    .select {
      padding: 0.1em;

      label {
        display: block;

        font-size: small !important;
      }

      select {
        margin: 0px 0 0 0;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        background-color: white;
        border-top: none;
        border-right: none;
        color: gray;
        font-size: small !important;
        border-left: none;
        outline: none;
        border: 1px solid #60606030;
        box-shadow: 1px 1px 5px #60606010;
      }
    }

    .search {
      position: relative;

      .fa-search {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 16px;
        right: 6px;
        font-size: small;
        color: $primarylight;
        display: flex;
        align-items: center;
        height: 100%;
      }

      input {
        margin: 0px 0 0 0;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        background-color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border: 1px solid #60606030;
        box-shadow: 1px 1px 5px #60606010;
        font-size: small;
      }
    }
  }

  .dthome {
    .div12 {
      display: grid;
      grid-template-columns: 1fr auto auto !important;
      gap: 10px;
      margin-bottom: 2em;
    }

    .div2row {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      height: 100%;
      gap: 1em;
    }
    .top {
      background-color: white;
      border-radius: 5px;
      padding: 1em;

      h3 {
        color: $primary;
        font-size: medium;
        margin-bottom: 5px;
        font-weight: 520;
      }
      p {
        color: gray;
        font-size: small;
        margin-bottom: 5px;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }

      .list {
        display: grid;
        gap: 1em;
        grid-template-columns: repeat(4, 1fr);

        .item {
          background-color: white;
          border: 1px solid #60606010;
          padding: 1em;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606010;
          cursor: pointer;

          .tt {
            display: grid;
            grid-template-columns: 1fr 44px;
            gap: 10px;
            perspective: 1000px;

            h4 {
              font-weight: 520;
              color: $primarylight;
              font-size: small;
            }

            img {
              max-width: 44px;
              width: 100%;
              object-fit: contain;
              rotate: 30deg;
              transform-style: preserve-3d;
              animation: flipIcon 3s linear 2s forwards;
            }
          }

          h2 {
            margin: 10px auto 10px auto;
            color: $secondary;
            font-size: x-large;
            font-weight: 560;
            display: block;
            width: fit-content; /* Standard property */
            width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          }

          button {
            padding: 5px 10px 5px 10px;
            margin-left: auto;
            display: block;
            color: $primarylight;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            background-color: transparent;
          }
        }
      }
    }

    .div3equal {
      gap: 1em;
    }

    .chrt {
      background-color: white;
      border-radius: 5px;
      padding: 1em;

      h3 {
        color: $primary;
        font-size: small;
        margin-bottom: 5px;
        font-weight: 520;
      }
      p {
        color: gray;
        font-size: smaller;
        margin-bottom: 5px;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }
    }
  }

  .data {
    background-color: white;
    padding: 1em;
    width: 100%;
    min-height: 70vh;

    .wrap {
      overflow-x: auto;
      padding-bottom: 2em;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        background-color: $primarylight;
        color: white;
        padding: 5px 10px;
         border: 1px solid #ffffff30;
        overflow-x: hidden;
        font-weight: 520;
      }
      tr {
        box-shadow: 1px 1px 2px #60606030;
        margin-bottom: 2px;

        td {
          padding: 5px 10px;
          border: 1px solid #60606030;
          border-collapse: collapse;
        }
      }
    }
  }

  .new {
    background-color: white;
    border-radius: 5px;

    .topbar {
      background-color: $primarylight;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1em;
      color: white;
      border-radius: 5px 5px 0 0;

      h4 {
        font-size: small;
        font-weight: 520;
      }
      p {
        font-size: small;
        cursor: pointer;
      }
    }

    .list {
      background-color: white;
      border-radius: 0 0 5px 5px;
      min-height: 50vh;
      padding: 1em;

      .item {
        padding: 10px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        border-radius: 5px;
        margin-bottom: 5px;
        position: relative;

        .ic-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 2%;
          height: 100%;
          display: grid;
          place-content: center;
          width: 20px;
          cursor: pointer;
          color: $primary;
        }
        .ic-edit:hover {
          color: $secondary;
        }

        .ic-link {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 6%;
          height: 100%;
          display: grid;
          place-content: center;
          width: 24px;
          cursor: pointer;
          color: $primarylight;
        }
        .ic-link:hover {
          color: $secondary;
        }

        .ic-data {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10%;
          height: 100%;
          display: grid;
          place-content: center;
          width: 20px;
          cursor: pointer;
          color: $primarylight;
        }
        .ic-data:hover {
          color: $secondary;
        }

        .fa {
          position: absolute;
          top: 0;
          left: 0;
          display: grid;
          place-content: center;
          width: 16px;
          object-fit: contain;
          cursor: pointer;
          color: $primarylight;
        }

        .ttitem {
          display: grid;
          grid-template-columns: auto 1fr 0.5fr 0.5fr 0.3fr;
          gap: 1em;

          h6 {
            padding: 4px;
            border: 1px solid $secondary;
            border-radius: 2px;
            color: $secondary;
            height: fit-content;
            font-weight: 400;
            margin: auto;
          }

          h3 {
            height: fit-content;
            margin: auto;
            font-size: small;
            font-weight: 500;
            padding: 5px 1em 5px 1em;
            color: $secondary;
            border: 1px solid $secondary;
            border-radius: 5px;
          }

          .dt {
            color: grey;
            font-style: italic;
            font-weight: 200;
          }

          h4 {
            margin-bottom: 4px;
            font-size: small;
            font-weight: 500;
          }

          p {
            color: gray;
            font-size: smaller !important;
            font-weight: 400 !important;
          }

          h5 {
            height: fit-content;
            margin: auto;
            color: $primarylight;
            font-size: small;
            font-weight: 400;
          }

          .fa {
            max-height: 44px;
            object-fit: contain;
            height: 100%;
            margin: auto;
            color: $primarylight;
            cursor: pointer;
          }
        }
      }
      .item:hover {
        box-shadow: 1px 1px 5px #60606030;
      }
    }

    .rmf {
      background-color: white;
      border-radius: 0 0 5px 5px;
      min-height: 50vh;
      padding: 1em;

      .bar {
        display: grid;
        grid-template-columns: repeat(2, auto);
        margin-bottom: 1.5em;
        background-color: $bg1;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        .item {
          p {
            font-size: small;
            padding: 5px;
            cursor: pointer;
            text-align: center;
            color: $primary;
          }
          p:hover {
            color: white;
            background-color: $primary;
          }
          .active {
            color: white;
            background-color: $primary;
          }
        }
      }

      .flist {
        min-height: 30vh;
        margin-bottom: 1em;

        .qitem {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          border-radius: 5px;
          margin-bottom: 5px;
          display: grid;
          grid-template-columns: auto 1fr 0.3fr 0.3fr 0.7fr auto;
          gap: 1em;
          position: relative;

          .fa-times {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 16px;
            display: grid;
            place-content: center;
            right: 1.5%;
            color: $secondary;
            cursor: pointer;
          }
          .fa-times:hover {
            color: $primarylight;
          }

          .fa-edit {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 4%;
            height: 100%;
            width: 16px;
            display: grid;
            place-content: center;
            color: $primary;
            cursor: pointer;
          }
          .fa-edit:hover {
            color: $primarylight;
          }

          .fa-moveup {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 7%;
            height: 100%;
            width: 16px;
            display: grid;
            place-content: center;
            color: $primarylight;
            cursor: pointer;
          }
          .fa-moveup:hover {
            color: $secondary;
          }

          .fa-movedown {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 9%;
            height: 100%;
            width: 16px;
            display: grid;
            place-content: center;
            color: $primarylight;
            cursor: pointer;
          }
          .fa-movedown:hover {
            color: $secondary;
          }

          p {
            display: flex;
            align-items: center;
          }

          label {
            padding: 4px;
            border: 1px solid $secondary;
            border-radius: 2px;
            color: $secondary;
            height: fit-content !important;
            font-weight: 400;
            margin: auto;
            font-size: small;
          }

          .dt {
            color: $bg;
            font-style: italic;
            font-weight: 200;
          }

          h3 {
            margin-bottom: 4px;
            font-size: small;
            font-weight: 520;
          }
          h5 {
            margin-bottom: 4px;
            font-size: small;
            font-weight: 520;
            color: $primary;
            font-style: italic;
          }

          p {
            color: gray;
            font-size: smaller !important;
            font-weight: 400 !important;
          }

          h5 {
            height: fit-content;
            margin: auto;
            color: $primarylight;
            font-size: small;
            font-weight: 400;
          }

          .fa {
            max-height: 44px;
            object-fit: contain;
            height: 100%;
            margin: auto;
            color: $primarylight;
            cursor: pointer;
          }
        }
      }

      .simple {
        position: relative;
        background-color: $bg1;
        padding: 1em;
        border-radius: 5px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        margin-bottom: 1em;

        .chlist {
          padding: 10px 0 10px 0;

          .ch {
            display: grid;
            grid-template-columns: auto 1fr auto;
            padding: 5px;
            width: 100%;
            background-color: white;
            border-radius: 5px;
            margin-bottom: 2px;
            gap: 1em;

            label {
              padding: 0 4px;
              border: 1px solid $secondary;
              border-radius: 2px;
              color: $secondary;
              height: fit-content !important;
              font-weight: 400;
              margin: auto;
              font-size: small;
            }
            h3 {
              font-size: small;
              font-weight: 400;
            }
          }
        }

        .div1auto {
          display: grid;
          grid-template-columns: 1fr auto;

          .add {
            height: 38px;
            width: 38px;
            color: $primarylight;
            margin: auto;
          }
        }

        p {
          background-color: $primary;
          border-radius: 5px;
          margin-left: auto;
          padding: 5px 10px 5px 10px;
          cursor: pointer;
          color: white;
          width: fit-content; /* Standard property */
          width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          font-size: small;
        }
        p:hover {
          background-color: $primarylight;
        }

        .div31 {
          margin-bottom: 0 !important;
        }

        .div12 {
          display: grid;
          grid-template-columns: 1fr auto auto;
          gap: 10px;

          .input-container,
          .select-container {
            width: 100%;
            min-width: 150px;
          }
        }

        .fa-times {
          position: absolute;
          top: 1em;
          right: 1em;
          color: $secondary;
          cursor: pointer;
        }
        .fa-times:hover {
          color: $primarylight;
        }
      }

      .choices {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 1em;

        div {
          padding: 1em;
          text-align: center;
          cursor: pointer;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          border-radius: 5px;
          background-color: $bg1;
          .fas {
            height: 24px;
            width: 24px;
            color: $primarylight;
            margin-bottom: 1em;
          }

          p {
            font-size: small;
          }
        }
        div:hover {
          box-shadow: 1px 1px 5px #60606030;
          background-color: white;
        }
      }

      h4 {
        margin-top: 1em;
        margin-bottom: 8px;
        font-size: small;
        font-weight: 520;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }

      .input {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        textarea,
        input {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
          font-size: small;
        }
        textarea:focus,
        input:focus {
          border-bottom: 1px solid $primary;
        }
      }
      .select {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        select {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          font-size: small;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
        }
      }

      h6 {
        height: 32px;
        line-height: 32px;
        color: $secondary;
        font-weight: 400;
        text-align: center;
      }

      button {
        background-color: $primarylight;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px;
        min-width: 200px;
        color: white;
        margin: auto;
        display: block;
      }
      button:hover {
        background-color: $primary;
      }
    }
  }

  @include maxwidth(mobile) {
    .new {
      .rmf {
        .choices {
          grid-template-columns: 1fr;
        }
      }
      .list {
        .item {
          .ttitem {
            grid-template-columns: 1fr;
          }

          .ic-data{
            top: -14%;
            right: 20%;
          }
          .ic-link{
            top: -14%;
            right: 10%;
          }
          .ic-edit{
            top: -14%;
            right: 5;
          }
        }
      }
    }
  }
}

@include maxwidth(tablet) {
  .list{
    .ic-data{
      right: 13%;
    }
    // .ic-link{
    //   right: 10%;
    // }
    // .ic-edit{
    //   right: 5;
    // }
  }
  
}

@keyframes flipIcon {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
