@import "./variables";
@import "./mediaquery";

.beneficiary {
  height: 100%;
  width: 100%;

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 0 0 5px 5px;
    position: relative;

    .loading {
      position: absolute !important;
      background-color: transparent !important;
    }

    hr {
      color: #60606010;
      margin: 10px 0 10px 0;
    }

    .utp {
      display: grid;
      grid-template-columns: 1fr auto auto auto;
      gap: 1em;

      h3 {
        color: $secondary;
        font-size: medium;
        font-weight: 520;
        display: flex;
        align-items: center;
      }

      p {
        font-size: small;
        color: white;
        background-color: $secondary;
        border-radius: 5px;
        border: 1px solid $secondary;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        .fa-add {
          margin-right: 5px;
        }
      }
      p:hover {
        color: $secondary;
        background-color: white;
      }

      .search {
        position: relative;

        .fa-search {
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          height: 100%;
          display: flex;
          align-items: center;
          color: #60606050;
        }

        input {
          width: 100%;
          padding: 8px;
          outline: none;
          border: 1px solid #60606050;
          font-size: small;
          border-radius: 5px;
        }
      }
    }

    .div31 {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1em;
      min-height: 60vh;

      .lcontainer {
        min-height: 50vh;
      }

      .user-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
        height: fit-content;

        .user-box {
          padding: 10px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          display: grid;
          grid-template-columns: auto 1fr;
          height: fit-content;
          gap: 10px;
          cursor: pointer;

          .left {
            h2 {
              background-color: $primary;
              border-radius: 5px;
              font-size: medium;
              font-weight: 600;
              padding: 0 10px;
              height: 54px;
              line-height: 54px;
              min-width: 74px;
              width: fit-content; /* Standard property */
              width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
              color: white;
              text-align: center;
            }

            h4 {
              margin-top: 10px;
              font-size: small;
              font-weight: 400;
              color: $primary;
              text-align: center;
            }
          }
          .right {
            h3 {
              margin-bottom: 3px;
              font-size: small;
              font-weight: 520;
            }
            p {
              font-size: small;
            }
          }
        }
        .user-box:hover {
          box-shadow: 1px 1px 5px $primarylight;
        }
        .active {
          box-shadow: 1px 1px 5px $primarylight;
        }
      }

      .selected {
        padding: 1em;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;

        h4 {
          font-weight: 520;
          font-size: small;
          margin: 5px 0 0 0;
        }

        p {
          font-size: small;
          margin: 4px 0 4px 0;
        }
        .fa-icon {
          color: $secondary;
          cursor: pointer;
        }
        .fa-icon:hover{
          color: $accent;
        }

        .actions {
          display: grid;
          grid-template-columns: 1fr 1fr auto;
          gap: 10px;
          margin-top: 2em;

          .fa {
            margin-right: 5px;
          }

          h6 {
            font-size: small;
            padding: 5px;
            background-color: $bg1;
            cursor: pointer;
            text-align: center;
            color: $secondary;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            border: 1px solid #60606010;
            font-weight: 400;
          }
          h6:hover {
            color: $primarylight;
          }
          :nth-child(3) {
            background-color: $secondary;
            color: white;
          }
        }

        .performance {
          gap: 10px;
          margin-top: 2em;

          h6 {
            font-size: small;
            padding: 5px 10px;
            background-color: $primarylight;
            cursor: pointer;
            text-align: center;
            color: white;
            box-shadow: 1px 1px 5px #60606030;
            border-radius: 5px;
            border: 1px solid #60606010;
            font-weight: 400;
          }
        }

        .sbeneficiary {
          height: 100%;
          display: grid;
          grid-template-rows: auto 1fr auto auto;

          .data {
            height: 100%;
            overflow-y: auto;

            .bars {
              width: fit-content; /* Standard property */
              width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
              display: grid;
              grid-template-columns: auto auto auto;

              h4 {
                padding: 5px 1em;
                cursor: pointer;
                font-weight: 400;
                background-color: $bg1;
                color: $primary;
                font-size: small;
              }
              .active {
                background-color: $primarylight;
                color: white;
              }
            }

            .itt {
              padding: 8px;
              border-radius: 5px;
              background-color: white;
              box-shadow: 1px 1px 5px #60606030;
              border: 1px solid #60606010;
              position: relative;
              margin-bottom: 5px;

              .trash {
                color: $primarylight;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
              }
              .trash:hover {
                color: $secondary;
              }

              .date {
                background-color: $primary;
                color: white;
                padding: 3px;
                border-radius: 2px;
                width: fit-content; /* Standard property */
                width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
              }

              p {
                font-size: x-small;              
              }
            }
          }
        }
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .wrap {
      max-width: 50vw;
      width: 582px;
      // width: fit-content; /* Standard property */
      // width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
      margin: auto;
      background-color: white;
      border-radius: 5px;
      box-shadow: 5px #60606050;
      overflow: hidden;
      position: relative;

      .head {
        display: grid;
        grid-template-columns: 1fr auto;
        background-color: $primary;
        padding: 8px;

        h3 {
          color: white;
          font-size: small;
          font-weight: 520;
        }
        .fa-times {
          color: orange;
          cursor: pointer;
        }
        .fa-times:hover {
          color: $secondary;
        }
      }

      .new {
        padding: 10px;
      }

      .loading {
        background-color: transparent !important;
      }

      hr {
        color: #60606010;
        margin: 0 0 10px 0 !important;
      }

      h6 {
        color: orange;
        text-align: center;
        font-size: small;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
      }

      .input,
      .select {
        margin: 2px 0 2px 0;
        display: grid;
        grid-template-columns: 1fr 150px;
        gap: 10px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        border-radius: 5px;

        select {
          padding: 7px;
          width: 100%;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: x-small;
          background-color: $bg1;
        }
        select:focus {
          border-bottom: 2px solid $primary;
        }

        .pwd {
          position: relative;
          margin: 0px 0 0 0;

          .fa-eye {
            position: absolute;
            top: 5px;
            bottom: 0;
            height: 100%;
            right: 5px;
            color: $secondary;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        label {
          display: block;
          margin: auto 0 auto 10px;
          height: fit-content;
          width: 100%;
          font-size: x-small;
        }

        input {
          padding: 5px;
          width: 100%;
 
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606010;
          border-radius: 5px;
          font-size: x-small;
          background-color: $bg1;
          text-align: center;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .utp {
      grid-template-columns: 1fr !important;
    }
    .div31 {
      grid-template-columns: 1fr !important;
      .user-list {
        grid-template-columns: 1fr !important;
      }
    }
    .popup {
      .wrap {
        max-width: 90vw;
      }
    }
  }
  @include maxwidth(tablet) {
    .div31 {
      grid-template-columns: 1fr !important;
    }
  }
}
