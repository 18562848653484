@import "./mediaquery";
@import "./variables.scss";

.dataentry {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;
  position: relative;

  .title {
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    gap: 10px;
    margin-bottom: 1em;
    align-items: center;

    .add {
      font-size: medium;
      padding: 10px;
      margin-right: auto;
      display: block;
      color: $primarylight;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      background-color: white;
    }
    .add:hover {
      box-shadow: 1px 1px 5px $primarylight;
    }

    .select {
      padding: 0.1em;

      label {
        display: block;

        font-size: small !important;
      }

      select {
        margin: 0px 0 0 0;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        background-color: white;
        border-top: none;
        border-right: none;
        color: gray;
        font-size: small !important;
        border-left: none;
        outline: none;
        border: 1px solid #60606030;
        box-shadow: 1px 1px 5px #60606010;
      }
    }

    .lsearch {
      position: relative;
      height: fit-content !important;
      margin-bottom: 1em;

      .input-container {
        margin-bottom: 0;
      }

      .fa-search {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 6px;
        font-size: small;
        color: $primarylight;
        display: grid;
        place-content: center;
        height: 100%;
        width: 16px;
      }
    }
  }

  .dthome {
    .filters {
      width: fit-content; /* Standard property */
      width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 10px;
      margin-left: auto;
    }
    .div31 {
      display: grid;
      grid-template-columns: 3fr 1fr;
      gap: 1em;
      margin-bottom: 2em;
    }

    .div2row {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      height: 100%;
      gap: 1em;
    }
    .top {
      background-color: white;
      border-radius: 5px;
      padding: 1em;

      h3 {
        color: $primary;
        font-size: medium;
        margin-bottom: 5px;
        font-weight: 520;
      }
      p {
        color: gray;
        font-size: small;
        margin-bottom: 5px;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }

      .list {
        display: grid;
        gap: 1em;
        grid-template-columns: repeat(3, 1fr);

        .item {
          background-color: white;
          border: 1px solid #60606010;
          padding: 1em;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606010;

          .tt {
            display: grid;
            grid-template-columns: 1fr 44px;
            gap: 10px;
            perspective: 1000px;

            h4 {
              font-weight: 520;
              color: $primarylight;
              font-size: small;
            }

            img {
              max-width: 44px;
              width: 100%;
              object-fit: contain;
              rotate: 30deg;
              transform-style: preserve-3d;
              animation: flipIcon 3s linear 2s forwards;
            }
          }

          h2 {
            margin: 10px auto 10px auto;
            color: $secondary;
            font-size: x-large;
            font-weight: 560;
            display: block;
            width: fit-content; /* Standard property */
            width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          }

          button {
            padding: 5px 10px 5px 10px;
            margin-left: auto;
            display: block;
            color: $primarylight;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            background-color: transparent;
          }
        }
      }
    }

    .div3equal {
      gap: 1em;
    }

    .chrt {
      background-color: white;
      border-radius: 5px;
      padding: 1em;
      position: relative;

      .save {
        position: absolute;
        right: 16px;
        top: 16px;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 10px;

        * {
          cursor: pointer;
        }
      }

      h3 {
        color: $primary;
        font-size: small;
        margin-bottom: 5px;
        font-weight: 520;
      }
      p {
        color: gray;
        font-size: smaller;
        margin-bottom: 5px;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }
    }
  }

  .new {
    background-color: white;
    border-radius: 5px;

    .topbar {
      background-color: $primarylight;
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1em;
      color: white;
      border-radius: 5px 5px 0 0;

      h4 {
        font-size: small;
        font-weight: 520;
      }
      p {
        font-size: small;
        cursor: pointer;
      }
    }

    .list {
      background-color: white;
      border-radius: 0 0 5px 5px;
      min-height: 50vh;
      padding: 1em;

      .item {
        padding: 10px;
        border: 1px solid #60606010;
        box-shadow: 1px 1px 5px #60606010;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        transition: box-shadow 0.5s ease-in-out;

        .ttitem {
          display: grid;
          grid-template-columns: auto 1fr 1fr 0.3fr 0.3fr 0.4fr;
          gap: 1em;
          align-items: center;

          h6 {
            padding: 4px;
            border: 1px solid $secondary;
            border-radius: 2px;
            color: $secondary;
            height: fit-content;
            font-weight: 400;
            margin: auto;
          }

          h3 {
            height: fit-content;
            margin: auto;
            font-size: small;
            font-weight: 500;
            padding: 5px 1em 5px 1em;
            color: $secondary;
            background-color: white;
            border: 1px solid $secondary;
            border-radius: 5px;
            width: 7em;
            text-align: center;
            cursor: pointer;
            transition: background-color 0.5s ease-in-out;
          }
          h3:hover {
            background-color: $secondary;
            color: white;
          }

          h4 {
            margin-bottom: 4px;
            font-size: small;
            font-weight: 500;
          }

          p {
            color: gray;
            font-size: smaller;
            font-weight: 400 !important;
          }

          h5 {
            height: fit-content;
            margin: auto;
            color: $primarylight;
            font-size: small;
            font-weight: 400;
          }
          .icons {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1em;
          }

          .fa {
            height: 100%;
            width: 20px;
            margin: auto;
            color: $primarylight;
            cursor: pointer;
            transition: color 0.5s ease-in-out;
          }
          .fa:hover {
            color: $primary;
          }
          .fa-trash {
            height: 16px;
            width: 16px;
            margin: auto;
            color: $secondary;
            cursor: pointer;
            transition: color 0.5s ease-in-out;
          }
          .fa-trash:hover {
            color: $primary;
          }
        }
      }
      .item:hover {
        box-shadow: 1px 1px 5px #60606050;
      }
    }

    .signature_pad {
      border: 3px solid $primarylight;
      background-color: whitesmoke;
      border-radius: 8px;
      position: relative;
      width: 100%;
      height: 130px;

      label {
        position: absolute;
        top: -1em;
        left: 1em;
        font-size: x-small;
        color: $primary;
        padding: 1px 16px;
        background-color: white;
        border-radius: 2px;
        border: 1px solid #ccc;
      }

      .clear {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $secondary;
        cursor: pointer;
      }

      .signature-canvas,
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .btn1 {
      background-color: $secondary !important;
      border: none;
      cursor: pointer;
      padding: 8px 2em;
      border-radius: 5px;
      color: white;
      display: block;
      margin-left: auto !important;
    }

    .file-input {
      position: relative;

      input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        background-color: white;
        border-radius: 4px;
        outline: none;
        transition: border-color 0.3s;
      }

      .ic {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        cursor: $secondary;
        height: 100%;
        width: 16px;
        display: grid;
        place-content: center;
      }
    }

    .taf {
      background-color: white;
      border-radius: 0 0 5px 5px;
      min-height: 50vh;

      .view {
        padding: 10px 1em;
        cursor: pointer;
        background-color: $bg;
        font-size: medium;
        box-shadow: 1px 1px 5px #60606030;
        transition: background-color 0.5s ease-in-out;
      }
      .view:hover {
        background-color: $primary;
        color: white;
      }

      h4 {
        margin-top: 1em;
        margin-bottom: 8px;
        font-size: small;
        font-weight: 520;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }

      h3 {
        font-size: medium;
        font-weight: 600;
        color: $primary;
        padding-bottom: 10px;
        border-bottom: 1px solid #60606030;
        margin-top: 2em;
        margin-bottom: 1em;
      }

      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 44px;
          padding: 10px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;
          z-index: 2;
          background-color: white;

          h4 {
            color: $primary;
            font-size: small;
            cursor: pointer;
            margin-bottom: 3px;
          }

          h4:hover {
            color: $primarylight;
          }

          p {
            font-size: x-small;
            margin-bottom: 3px;
          }

          hr {
            border-top: 1px solid $line;
          }
        }
      }

      .benef-details {
        padding: 10px;
        border: 1px solid #60606030;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        z-index: 1;
        background-color: white;
        margin-bottom: 1em;

        p {
          color: $primary;
          margin-bottom: 3px;
          display: grid;
          font-size: small;
          gap: 5px;
          grid-template-columns: repeat(2, 1fr);
          span {
            background-color: $bg1;
            padding: 2px;
          }
        }
      }

      .file-input {
        position: relative;

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          background-color: white;
          border-radius: 4px;
          outline: none;
          transition: border-color 0.3s;
        }

        .ic {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          cursor: $secondary;
          height: 100%;
          width: 16px;
          display: grid;
          place-content: center;
        }
      }

      h6 {
        height: 32px;
        line-height: 32px;
        font-size: medium;
        color: $secondary;
        font-weight: 400;
        text-align: center;
        margin: 10px 0;
      }

      button {
        background-color: $primarylight;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px;
        min-width: 200px;
        color: white;
        margin: auto;
        display: block;
      }
      button:hover {
        background-color: $primary;
      }
    }

    .rmf {
      background-color: white;
      border-radius: 0 0 5px 5px;
      min-height: 50vh;
      padding: 1em;

      .bar {
        display: grid;
        grid-template-columns: repeat(6, auto);
        margin-bottom: 1.5em;
        background-color: $bg1;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        .item {
          p {
            font-size: small;
            padding: 5px;
            cursor: pointer;
            text-align: center;
            color: $primary;
          }
          p:hover {
            color: white;
            background-color: $primary;
          }
          .active {
            color: white;
            background-color: $primary;
          }
        }
      }

      h4 {
        margin-top: 1em;
        margin-bottom: 8px;
        font-size: small;
        font-weight: 520;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }

      .input {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        textarea,
        input {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
          font-size: small;
        }
        textarea:focus,
        input:focus {
          border-bottom: 1px solid $primary;
        }
      }
      .select {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        select {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          font-size: small;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
        }
      }

      h6 {
        height: 32px;
        line-height: 32px;
        color: $secondary;
        font-weight: 400;
        text-align: center;
      }

      button {
        background-color: $primarylight;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px;
        min-width: 200px;
        color: white;
        margin: auto;
        display: block;
      }
      button:hover {
        background-color: $primary;
      }
    }

    .arf {
      background-color: white;
      border-radius: 0 0 5px 5px;
      min-height: 50vh;
      padding: 1em;

      .bar {
        display: grid;
        grid-template-columns: repeat(2, auto);
        margin-bottom: 1.5em;
        background-color: $bg1;
        border-radius: 5px 5px 0 0;
        overflow: hidden;

        .item {
          p {
            font-size: small;
            padding: 5px;
            cursor: pointer;
            text-align: center;
            color: $primary;
          }

          p:hover {
            color: white;
            background-color: $primary;
          }

          .active {
            color: white;
            background-color: $primary;
          }
        }
      }

      .flist {
        min-height: 30vh;
        margin-bottom: 1em;

        .pitem {
          padding: 5px;
          border: 1px solid #60606010;
          box-shadow: 1px 1px 5px #60606010;
          border-radius: 5px;
          margin-bottom: 5px;
          display: grid;
          align-items: center;
          gap: 1em;
          position: relative;

          .fa-times {
            position: absolute;
            top: 2px;
            right: 2px;
            color: $secondary;
            cursor: pointer;
          }

          .fa-times:hover {
            color: $primarylight;
          }

          p {
            display: flex;
            align-items: center;
          }

          label {
            padding: 4px;
            border: 1px solid $secondary;
            border-radius: 2px;
            color: $secondary;
            height: fit-content !important;
            font-weight: 400;
            margin: auto;
            font-size: small;
          }

          .dt {
            color: $bg;
            font-style: italic;
            font-weight: 200;
          }

          h3 {
            margin-bottom: 4px;
            font-size: small;
            font-weight: 520;
          }

          p {
            color: gray;
            font-size: smaller !important;
            font-weight: 400 !important;
          }

          h5 {
            height: fit-content;
            margin: auto;
            color: $primarylight;
            font-size: small;
            font-weight: 400;
          }

          .fa {
            width: 16px;
            object-fit: contain;
            height: 100%;
            margin: auto;
            color: $primarylight;
            cursor: pointer;
          }
        }
      }

      .popup {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        align-items: center;
        z-index: 1;
        animation: zoom 1s ease-in-out;

        .container {
          background-color: white;
          border-radius: 5px;
          padding: 20px;
          margin: auto;
          color: #000000;
          min-width: 50vw;
          max-width: 70vw;
          min-height: 50vh;
          height: fit-content;
          overflow-y: auto;
          width: fit-content; /* Standard property */
          width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          position: relative;

          .fa-x {
            position: absolute;
            right: 1em;
            top: 10px;

            cursor: pointer;
            color: $primary;
          }

          .fa-x:hover {
            color: $accent;
          }
        }

        @include maxwidth(mobile) {
          .container {
            max-width: 85% !important;
          }
        }

        @include maxwidth(tablet) {
          .container {
            max-width: 85% !important;
          }
        }

        .dets {
          display: grid;
          grid-template-columns: 1fr auto;

          .fa {
            color: $accent;
            height: fit-content;
            margin: auto;
            padding: 5px;
          }

          .fa:hover {
            color: $primary;
          }
        }

        .content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 5px;
        }

        hr {
          margin-bottom: 10px;
        }
      }

      h4 {
        margin-top: 1em;
        margin-bottom: 8px;
        font-size: small;
        font-weight: 520;
      }
      hr {
        color: #60606010;
        margin-bottom: 10px;
      }

      .input {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        textarea,
        input {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
          font-size: small;
        }
        textarea:focus,
        input:focus {
          border-bottom: 1px solid $primary;
        }
      }
      .select {
        padding: 0.1em;

        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: small !important;
        }

        select {
          margin: 0px 0 16px 0;
          padding: 10px;
          width: 100%;
          font-size: small;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
        }
      }

      h6 {
        height: 32px;
        line-height: 32px;
        color: $secondary;
        font-weight: 400;
        text-align: center;
      }

      .btnadd {
        background-color: $primary;
        border-radius: 5px;
        margin-right: auto;
        padding: 5px 10px 5px 10px;
        cursor: pointer;
        color: white;
        width: fit-content; /* Standard property */
        width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
        font-size: small;
      }

      .btnadd:hover {
        background-color: $primarylight;
      }

      button {
        background-color: $primarylight;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding: 8px;
        min-width: 200px;
        color: white;
        margin: auto;
        display: block;
      }
      button:hover {
        background-color: $primary;
      }
    }
  }

  @include maxwidth(mobile) {
    .title {
      grid-template-columns: 1fr;
    }
    .dthome {
      .div31 {
        grid-template-columns: 1fr;
        .list {
          grid-template-columns: 1fr;
        }
      }
    }
    .list {
      .ttitem {
        grid-template-columns: auto 0.5fr 0.3fr 1fr 1fr 1fr;
        gap: 5px !important;

        h6 {
          display: none;
        }
        p {
          display: none;
        }
        h3 {
          font-size: xx-small !important;
          width: fit-content; /* Standard property */
          width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
        }
        .icons {
          display: grid !important;
          gap: 1em;
        }
      }
    }
    .rmf {
      .bar {
        width: 100%;
        overflow-x: auto !important;
      }
    }
  }
  @include maxwidth(tablet) {
    .dthome {
      .div31 {
        grid-template-columns: 1fr;
        .list {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@keyframes flipIcon {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
