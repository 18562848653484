@import "./variables";
@import "./mediaquery";

.login {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-image: url("../assets/imgs/Background.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-rows: auto 1fr;

  .top_logos {
    max-width: 85%;
    width: 100%;
    margin: 1em auto 1em auto;
    display: grid;
    grid-template-columns: auto 1fr auto;

    :first-child {
      max-height: 64px !important;
    }

    img {
      max-height: 24px;
      height: 100%;
      object-fit: contain;
      display: block;
      margin: auto;
    }

    @include maxwidth(mobile) {
      :first-child {
        max-height: 36px !important;
      }
      img {
        max-height: 24px !important;
      }
    }
    @include maxwidth(tablet) {
      :first-child {
        max-height: 36px !important;
      }
      img {
        max-height: 24px !important;
      }
    }
  }

  .leftdeco {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 0.8em;

    .right_panel {
      img {
        max-width: 100vw;
        width: 100%;
        height: 100vh;
        object-fit: fill;
        object-position: center;
      }
    }
  }

  .card {
    max-width: 55%;
    width: 100%;
    height: fit-content;
    display: block;
    margin: auto;
    box-shadow: 2px 2px 10px #60606030;
    z-index: 1;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 100%;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 1em;
        background-color: rgba($color: $primary, $alpha: 0.31);
        border: 0 1px 0 1px solid rgba($color: #000000, $alpha: 0.3);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 5px;

        .sys {
          position: relative;
          width: 85%;
          margin: auto;
          display: grid;
          grid-template-rows: auto;
          text-align: center;
          color: $white;
        }

        .attribution {
          text-align: center;
          padding: 0 0 15px 0;
          p {
            color: $white;
            font-weight: 100;
          }
          a {
            color: $secondary;
            font-weight: 600;
            text-decoration: none;
          }
        }
      }

      .right {
        position: relative;
        width: 100%;
        padding: 1em 2em;
        display: grid;
        grid-template-rows: repeat(3, auto);
        gap: 0.5em;
        margin: auto;
        background-color: $primarylighter;

        .input {
          padding: 0.1em;
          label {
            display: block;
            margin: 0px 0 10px 0;
            font-size: smaller;
            font-weight: 500;
          }

          input {
            border: 1px solid $primary;
          }

          input:focus {
            border: 1px solid $secondary;
          }
        }

        img {
          max-height: 64px;
          height: 100%;
          object-fit: contain;
          display: block;
          margin: 0 auto 0 auto;
        }

        .err {
          text-align: center;
          color: $secondary;
          font-size: small;
          font-weight: 400;
          height: 16px;
          line-height: 16px;
        }
        h3 {
          font-style: normal;
          font-size: x-large;
          text-align: center;
          color: $primary;
          padding-top: 0;
        }
        h4 {
          font-size: medium;
          text-align: center;
          margin: 8px 0 8px 0;
          color: $dark;
        }
        p {
          font-size: small;
          text-align: center;
          color: black;
          padding: 0.5em;
          a {
            color: $secondary;
            cursor: pointer;
            font-size: small;
          }
          a:hover {
            color: $secondary;
          }
        }
      }
    }
  }

  .forgot {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    background-color: rgba($color: #000000, $alpha: 0.2);
    padding: 1em;

    .card1 {
      background-color: white;
      z-index: 1;
      padding: 1em 2em;
      border-radius: 10px;
      max-width: 400px;
      width: 100%;
      position: relative;
      margin: auto;

      p {
        margin: 5px 0;
        font-size: small;
        font-weight: 510;
      }

      .err {
        text-align: center;
        color: $secondary;
        font-size: small;
        font-weight: 400;
        height: 10px;
        line-height: 10px;
      }

      .fa-times {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $secondary;
        cursor: pointer;
      }
      .fa-times:hover {
        color: orange;
      }

      h3 {
        text-align: center;
        font-size: large;
        font-weight: 520;
        color: $primary;
      }

      .input {
        padding: 0.1em;
        label {
          display: block;
          margin: 0px 0 10px 0;
          font-size: smaller;
          font-weight: 500;
        }

        input {
          border: 1px solid $primary;
        }

        input:focus {
          border: 1px solid $secondary;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .mob {
      display: block;
      text-align: center;
      position: absolute;
      top: 1em;
      left: 0;
      right: 0;
      z-index: 1;

      * {
        margin-top: 10px;
        font-size: large;
        font-weight: bolder;
        color: white;
      }
    }
    .leftdeco {
      right: 0 !important;

      .right_panel {
        img {
          max-width: 100vw !important;
        }
      }
    }

    .card {
      max-width: 85vw !important;
      padding: 0 !important;
      .container {
        grid-template-columns: 1fr !important;
        .left {
          display: none !important;
        }
        .right {
          height: 100%;
          padding: 2em !important;
          background-color: rgba($color: white, $alpha: 0.8) !important;
        }
      }
    }

    .contain {
      left: 0 !important;
    }
  }

  @include maxwidth(tablet) {
    .leftdeco {
      right: 0 !important;

      .right_panel {
        img {
          max-width: 100vw !important;
        }
      }
    }

    .card {
      max-width: 85vw !important;
      padding: 0 !important;
      .container {
        grid-template-columns: 1fr !important;
        .left {
          display: none !important;
        }
        .right {
          padding: 2em !important;
          background-color: rgba($color: white, $alpha: 0.8) !important;
        }
      }
    }
  }
}
