@import "./mediaquery";
@import "./variables.scss";

.questionnaire {
  .qheader {
    background-color: white;
    box-shadow: 0px 2px 5px #60606030;
    width: 100%;

    .qcontainer {
      max-width: 85vw;
      width: 100%;
      padding: 10px 0;
      display: grid;
      grid-template-columns: auto 1fr auto;
      margin: auto;
      gap: 1em;

      .usaid {
        max-height: 54px;
        height: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
      }
      h1 {
        font-size: x-large;
        display: block;
        margin: auto;
        width: 100%;
        color: $primary;
      }
      .wkwp {
        max-height: 34px;
        height: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
      }
    }
  }

  .tableheader {
    background-color: $primary;
    box-shadow: 2px 2px 5px #60606030;
    width: 100%;
    border: 1px solid #60606030;
    padding: 1em 1em 8em 1em;
    z-index: 11;

    .tcontainer {
      max-width: 85vw;
      width: 100%;
      margin: auto;
    }

    h4 {
      font-size: xx-large;
      color: white;
      margin: 10px 0;
      text-align: center;
      border-bottom: 1px solid #60606030;
      padding-bottom: 10px;
    }

    p {
      color: white;
      font-size: medium;
      margin: 10px 0;

      b {
        color: white;
      }
    }
  }

  .questions {
    background-color: white;
    box-shadow: 2px 2px 5px #60606030;
    margin: -4em auto 1em auto;
    max-width: 85vw;
    width: 100%;
    height: 100%;
    padding: 1em 1em 1em 1em;
    z-index: -1;

    .filled {
      display: grid;
      place-content: center;
      min-height: 30vh;

      h4 {
        font-size: x-large;
        color: $primarylight;
      }
    }

    h3 {
      font-size: x-large;
      color: $primary;
      margin-bottom: 10px;
    }

    h6 {
      text-align: center;
      color: $secondary;
      font-weight: 500;
      font-size: medium;
    }

    p {
      font-size: medium;
      color: gray;
    }

    .other {
      p {
        font-size: medium;
        color: gray;
        margin-bottom: 10px;
      }
      .input {
        margin: 1em 0 !important;
      }
    }

    .quiz {
      padding: 1em 2em;
      min-height: 30vh;

      .input,
      .select {
        margin: 3em 0 !important;

        label {
          font-size: large !important;
          display: block;
        }

        select,
        input {
          border: 1px solid gray;
          margin-top: 10px;
          padding: 8px;
        }

        select:focus,
        input:focus,
        textarea:focus {
          outline: 1px solid $primarylight;
          border: none;
        }

        textarea {
          width: 100%;
          border: 1px solid gray;
          margin-top: 10px;
          border-radius: 5px;
          font-size: small;
          padding: 8px;
          outline: none;
        }
      }
    }
  }
}
