@import "./Styles/mediaquery";
@import "./Styles/variables";
@import "./Styles/3_NotFound";
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

.sr-only {
  display: none;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
  background-color: $bg1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}
body {
  background-color: $bg1;
  display: table;
  height: 100%;
  width: 100%;
  position: relative;
}

h4 {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

h4.title {
  color: $primary;
  font-size: medium;
  font-weight: 600;
}
p.small-title {
  color: $accent;
  font-size: small;
  font-weight: 400;
  line-height: 191.023%;
  padding: 5px 0 5px;
}
.text-right {
  text-align: right;
}

.header {
  padding: 10px 1em;
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 10px;
  background-color: $white;
  width: 100%;
  color: $primary;

  p {
    font-size: small;
    display: flex;
    align-items: center;

    .user {
      margin-right: 5px;
      height: 24px;
      width: 24px;
      padding: 2px;
    }
    span {
      margin-left: 10px;
    }

    b {
      font-weight: 520;
    }
  }

  .lt {
    display: flex;
    align-items: center;
  }

  .fa-bars {
    cursor: pointer;
    color: $primarylight;
    display: none;
    align-items: center;
  }

  .userPopup {
    width: 200px;
    height: 100px;
    border-radius: 2px solid red;
  }

  img {
    max-height: 32px;
    height: 100%;
    object-fit: contain;
    margin: 0 0 0 auto;
    display: block;
  }

  @include maxwidth(mobile) {
    .fa-bars {
      display: flex;
    }
    p {
      font-size: xx-small;
    }
  }
  @include maxwidth(tablet) {
    .fa-bars {
      display: flex;
    }
  }
}

.mobheader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1;
  height: 100%;
  width: 100%;
  padding: 4em 1em 4em 1em;
  overflow-y: auto;
  animation: slide 2s ease-in-out;

  .fa-times {
    position: absolute;
    top: 1em;
    right: 1em;
    color: $secondary;
    cursor: pointer;
  }

  h1 {
    text-align: center;
    margin: 1em 0 1em 0;
    color: $primarylight;
  }

  hr {
    margin: 10px 0 10px 0;
    color: #60606030;
  }

  img {
    max-width: 70vw;
    width: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto 0 auto;
  }

  .item {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;
    padding: 10px 1em 10px 1em !important;
    cursor: pointer;
    height: fit-content !important;

    * {
      font-size: small;
      color: $primary;
      display: grid;
      margin: auto 0 auto 0;
    }

    .fa {
      font-size: large;
      display: grid;
      margin: auto;
    }
  }

  .active {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;
    padding: 10px 1em 10px 1em !important;
    cursor: pointer;
    height: fit-content !important;
    background-color: $primary;
    border-radius: 5px;
    margin: 1em 0 1em 0;

    * {
      font-size: medium;

      color: white;
      display: grid;
      margin: auto 0 auto 0;
    }

    .fa {
      font-size: large;
      display: grid;
      margin: auto;
    }
  }

  a {
    cursor: pointer;
    padding: 4px 4em 4px 4em;
    display: block;
    font-size: small;
    text-decoration: none;
    color: gray;
  }
  @include maxwidth(tablet) {
    width: 60%;
  }
}
@keyframes slide {
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
}

.button {
  button {
    display: block;
    margin: 1em auto 1em auto;
    padding: 8px 2em 8px 2em;
    width: fit-content; /* Standard property */
    width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
    background: linear-gradient(
      180deg,
      $primary 0%,
      $primarylight 50%,
      $primary 100%
    );
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #60606030;
  }

  button:hover {
    background: linear-gradient(
      180deg,
      $primarylight 0%,
      $primary 50%,
      $primarylight 100%
    );
  }
}

.navigation {
  height: 100vh;
  overflow-y: auto;
  background-color: $white;
  padding: 0 1em 0 1em;
  .logo {
    color: $primary;
    text-align: right;
    padding: 1.5em 10px 0 10px;

    .fa-x {
      cursor: pointer;
    }

    h3 {
      margin: 10px 0 10px 0;
      font-size: medium;
    }

    img {
      max-width: 70px;
      width: 100%;
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  .line-container {
    position: relative;
    width: 90%;
    height: 2px;
    background-color: $secondary;
    margin: 1em auto;

    .circle-point {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $secondary;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
    }

    .start-point {
      left: 0;
    }

    .end-point {
      right: 0;
    }
  }

  .item {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;
    padding: 10px 1em 10px 1em !important;
    cursor: pointer;
    height: fit-content !important;

    * {
      font-size: small;
      color: $primary;
      display: grid;
      margin: auto 0 auto 0;
    }

    .fa {
      font-size: large;
      display: grid;
      margin: auto;
    }
  }

  .active {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;
    padding: 10px 1em 10px 1em !important;
    cursor: pointer;
    height: fit-content !important;
    background-color: $primary;
    border-radius: 5px;

    * {
      font-size: small;

      color: white;
      display: grid;
      margin: auto 0 auto 0;
    }

    .fa {
      font-size: large;
      display: grid;
      margin: auto;
    }
  }

  a {
    cursor: pointer;
    padding: 4px 4em 4px 4em;
    display: block;
    font-size: small;
    text-decoration: none;
    color: gray;
  }

  .sysname {
    color: $primary;
    text-align: center;
    padding: 1.5em 10px 0 10px;

    h3 {
      margin: 10px 0 10px 0;
      font-size: medium;
    }
  }

  .contactdev {
    color: $white;
    text-align: center;
    padding: 1.5em 10px 0 10px;
    position: relative;

    img {
      max-width: 70px;
      width: 100%;
      display: block;
      margin: 0 auto 10px auto;
    }

    .contactdevtxt {
      position: absolute;
      margin: 10px 0 10px 0;
      font-size: smaller;
      top: 0;
      left: 0;
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 40px;

      p {
        margin-top: 10px;
      }

      .osl {
        font-weight: bold;
      }

      .devlink {
        background-color: $white;
        padding: 6px 10px;
        border-radius: 10px;
        color: $primary;
      }
    }
  }

  @include maxwidth(mobile) {
    a {
      padding: 4px 1em 4px 1em !important;
    }
  }
  @include maxwidth(tablet) {
    a {
      padding: 4px 1em 4px 1em !important;
    }
  }
}

@include maxwidth(mobile) {
  .none {
    display: none;
  }
}
.div1auto {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1em;

  @include maxwidth(tablet) {
    grid-template-columns: 1fr !important;
  }

  @include maxwidth(mobile) {
    grid-template-columns: 1fr !important;
  }
}
.div31 {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1em;
  margin-bottom: 2em;

  @include maxwidth(tablet) {
    grid-template-columns: 1fr !important;
  }

  @include maxwidth(mobile) {
    grid-template-columns: 1fr;
  }
}

.div2row {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1em;

  @include maxwidth(tablet) {
    grid-template-rows: 1fr !important;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
    gap: 1em;
  }
}

.div1auto {
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100%;
  gap: 1em;
}

.div2equal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;

  @include maxwidth(mobile) {
    display: block !important;
  }
}

.div3equal {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @include maxwidth(mobile) {
    grid-template-columns: 1fr;
  }
}

.div4equal {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @include maxwidth(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include maxwidth(mobile) {
    grid-template-columns: 1fr;
  }
}

.div3row {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
}

.cpagination {
  display: flex;
  gap: 10px;
  width: fit-content; /* Standard property */
  width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
  margin: 10px auto 10px auto;

  p {
    display: block;
    min-width: 24px;
    height: 24px;
    padding: 0 4px;
    width: fit-content; /* Standard property */
    width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
    margin: auto;
    font-weight: 400;
    background-color: $primary;
    text-align: center;
    line-height: 24px !important;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5 {
    display: block;
    min-width: 24px;
    padding: 5px;
    width: fit-content; /* Standard property */
    width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
    margin: auto;
    font-weight: 400;
    background-color: $primary;
    text-align: center;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5:hover {
    background-color: $secondary;
  }

  .active {
    background-color: $secondary;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    background-color: $secondary;
    cursor: pointer !important;
    font-size: small;
    border-radius: 6px;
    color: white;
    width: 16px;
    text-align: center;
    height: 16px !important;
    line-height: 16px !important;
    padding: 5px;
  }
  .fa-arrow-right:hover,
  .fa-arrow-left:hover {
    background-color: $secondary;
  }
}

.input {
  .pwd {
    position: relative;
    margin: 0px 0 10px 0;

    .fa-eye {
      position: absolute;
      top: 0;
      bottom: 0;
      border: 1px solid red;
      right: 5px;
      color: $secondary;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  label {
    margin-bottom: 16px;
    font-size: small;
  }

  input {
    padding: 7px;
    width: 100%;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    outline: none;
    border: 1px solid #60606010;
    border-radius: 5px;
    font-size: small;
  }
  input:focus {
    border-bottom: 2px solid $primary;
  }
}

.input-container {
  position: relative;
  margin-bottom: 1em;
  width: 100%;

  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    transition: top 0.3s, font-size 0.3s, color 0.3s;

    &.up {
      top: 0;
      font-size: x-small;
      color: $primary;
      padding: 1px 16px;
      background-color: white;
      border-radius: 2px;
      border: 1px solid #ccc;
    }
  }

  input {
    min-width: 150px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  textarea {
    min-width: 150px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  &.focused {
    textarea,
    input {
      border-color: $secondary;
    }
  }
}

.select-container {
  position: relative;
  margin-bottom: 1em;
  width: 100%;

  .label {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    transition: top 0.3s, font-size 0.3s, color 0.3s;

    &.up {
      top: 0;
      font-size: x-small;
      color: $primary;
      padding: 1px 16px;
      background-color: white;
      border-radius: 2px;
      border: 1px solid #ccc;
    }
  }

  select {
    min-width: 150px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }

  &.focused {
    select {
      border-color: $secondary;
    }
  }
}

.select {
  .pwd {
    position: relative;
    margin: 0px 0 10px 0;

    .fa-eye {
      position: absolute;
      top: 18%;
      right: 5px;
      color: $secondary;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  label {
    margin-bottom: 10px;
    font-size: small;
  }

  select {
    padding: 7px;
    width: 100%;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    outline: none;
    border: 1px solid #60606010;
    border-radius: 5px;
    font-size: small;
  }
  select:focus {
    border-bottom: 2px solid $primary;
  }
}

.SignaturePad {
  border: 1px solid #60606030;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1em;
  margin-top: 1em;
  .SignaturePad {
    border: 1px solid #60606030;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 1em;
  }
}

// /* width */
// ::-webkit-scrollbar {
//   width: 10px;
//   height: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $dark;
//   border-radius: 10px;
// }

.error {
  height: 30vh !important;
  display: flex !important;
  justify-items: center !important;

  p {
    width: 80% !important;
    display: block !important;
    color: gray !important;
    text-align: center !important;
    height: fit-content !important;
    margin: auto !important;
    font-size: large !important;
  }
}

.ripple {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.2);
  display: flex;
  align-items: center;

  .wrap {
    width: fit-content; /* Standard property */
    width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
    margin: auto;
  }

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}

//loading animation
.loading {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0);
  z-index: 10;

  .wrap {
    width: fit-content; /* Standard property */
    width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
    height: fit-content;
    margin: auto;

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $secondary;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
}

//map tooltip
.label {
  background: rgba(255, 255, 255, 0);
  border: 0;
  border-radius: 0px;
  box-shadow: 0 0px 0px;
}

//remove arrows input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  -webkit-appearance: textfield;
}

.wave_loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, $primary, $secondary);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }

  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
}

.report_loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .lds-spinner div {
    transform-origin: 30px 30px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 23px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
