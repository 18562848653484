@import "./mediaquery";
@import "./variables";

.single-county {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow: auto;

  .stats {
    padding: 0 !important;
  }

  .top-part {
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr;
    gap: 1em;
    margin-bottom: 2em;

    .topcharts {
      padding: 10px;
      background-color: $white;
      border-radius: 8px;
      box-shadow: 1px 1px 5px #60606010;
      display: grid;
      grid-template-rows: 1fr auto;

      .topsummary {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        .tp_item {
          background-color: white;
          border-radius: 8px;

          .text {
            padding: 8px;

            img {
              width: 24px;
              height: 24px;
              object-fit: contain;
            }

            h4 {
              color: $secondary;
              align-self: center;
              font-size: large;
              text-align: center;
              font-weight: 520;
              margin: 5px 0 5px 0;
            }

            h3 {
              color: $primary;
              align-self: center;
              font-weight: 520;
              font-size: small;
            }

            h6 {
              margin-top: 4px;
              color: $bg;
              align-self: center;
              font-size: x-small;
              font-weight: 400;
            }
          }
        }
      }
    }

    .chart {
      background-color: white;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 5px #60606010;
      padding: 1em;

      .tp {
        display: flex;
        justify-content: space-between;

        .download {
          height: 16px;
          width: 16px;
          color: $secondary;
          cursor: pointer;
        }
        .download:hover {
          color: $primarylight;
        }

        h3 {
          color: $primary;
          font-size: medium;
          margin-bottom: 5px;
          font-weight: 520;
        }
        p {
          color: gray;
          font-size: small;
          margin-bottom: 5px;
        }
        hr {
          color: #60606010;
          margin-bottom: 10px;
        }
      }

      h3 {
        color: gray;
        font-size: medium;
        margin-bottom: 1em;
        font-weight: 600;
      }

      .save {
        position: absolute;
        right: 16px;
        top: 16px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;

        * {
          cursor: pointer;
        }
      }
    }
  }

  .indicator_section {
    padding: 1em;
    background-color: white;
    margin-top: 2em;
    box-shadow: 1px 1px 5px #60606010;
    border-radius: 5px;
    position: relative;

    .loading {
      position: absolute !important;
    }

    .sbody {
      display: grid;
      grid-template-columns: 0.1fr 1fr 0.2fr 0.2fr 0.5fr 0.1fr;
      gap: 10px;
      padding: 5px;
      margin-bottom: 10px;


      h3 {
        font-weight: 520;
        font-size: small;
        padding: 5px;
      }

      .perf {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 5px;
      }
    }
    .div1auto {
      border-bottom: 1px solid $line;
    }
    .filter {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
      .fa {
        color: $primarylight;
      }
      p {
        color: $primary;
        font-size: small;
        font-weight: 400;
      }
    }

    .ind_list {
      min-height: 20vh;

      .tbody {
        background: white;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        box-shadow: 1px 1px 5px #60606010;
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.1fr 0.75fr 0.2fr 0.2fr 0.5fr 0.1fr;
        gap: 10px;

        .ic {
          margin-right: 10px;
          font-size: large;
        }

        h4 {
          font-weight: 400;
          text-align: center;
          margin: auto;
          border-left: 1px solid #60606010;
          border-right: 1px solid #60606010;
          font-size: small;
          color: $bg;
          width: 100%;
        }

        h5 {
          font-weight: 400;
          text-align: center !important;
          border-left: 1px solid #60606010;
          border-right: 1px solid #60606010;
          font-size: small;
          color: white;
          background-color: $primarylighter;
          padding: 10px;
          border-radius: 44px;
          width: 100%;
          height: fit-content;
        }

        .perf {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 5px;

          p {
            font-size: small;
            text-align: center;
            margin: auto;
          }
        }

        .achievement {
          width: fit-content; /* Standard property */
          width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          margin: auto 0 auto auto;
        }

        label {
          border: 1px solid $secondary;
          padding: 8px;
          font-size: small;
          width: fit-content; /* Standard property */
          width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          border-radius: 5px;
        }

        p {
          font-size: small;
          margin: auto;
          width: 100%;
        }

        .left {
          display: grid;
          grid-template-columns: 0.3fr 1fr;
          align-items: center;
          gap: 1em;
          .ind-code {
            text-align: center;
            border: 1px solid $secondary;
            color: $secondary;
            padding: 6px;
            border-radius: 5px;
          }
          .ind-desc {
            color: $accent;
            border-right: 1px solid $accent;
          }
          p {
            text-wrap: balance;
          }
        }
        .right {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          align-items: center;
          gap: 1em;

          p {
            cursor: pointer;
            color: $accent;
          }

          .badge {
            border-right: 1px solid $accent;
            border-left: 1px solid $accent;
            padding: 7px;
            width: 85px;
            p {
              background: $primarylighter;
              color: $secondary;
              text-align: center;
              padding: 2px;
              border-radius: 15px;
            }
          }
          .q4 {
            border-right: 1px solid $accent;
            padding: 7px;
            margin-left: -7px;
          }
          .achievement {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }

  .red {
    color: $secondary;
  }
  .blue {
    color: $primary;
  }
  .perform,
  .part3,
  .part4,
  .part5 {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
    position: relative;
    .top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      border-bottom: 1px solid $line;
      .filter {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        .fa {
          color: $primarylight;
        }
        p {
          color: $primary;
          font-size: small;
          font-weight: 400;
        }
      }
    }
  }

  .part {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1em;
    margin-bottom: 2em;
    .left {
      background-color: white;
      padding: 1em;
      border-radius: 5px;
      margin-bottom: 1em;
      position: relative;
    }
    .midtop-cards {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 1em;

      .itmbox {
        background: white;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606010;
        padding: 10px;

        h4 {
          color: $secondary;
          align-self: center;
          font-size: large;
          text-align: center;
          font-weight: 520;
          margin: 1em 0 1em 0;
        }

        h3 {
          color: $primary;
          align-self: center;
          font-weight: 500;
          font-size: x-small;
        }

        h6 {
          margin-top: 4px;
          color: $primarylight;
          align-self: center;
          font-size: small;
          font-weight: 400;
        }

        p {
          margin-top: 4px;
          color: $bg;
          align-self: center;
          font-size: x-small;
          font-weight: 400;
        }
      }
      .wrm_itmbox {
        background: $primarylighter;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606010;
        padding: 10px;

        h4 {
          color: $secondary;
          font-size: large;
          text-align: center;
          font-weight: 520;
          margin: 1em 0 1em 0;
        }

        h3 {
          color: $primary;
          text-align: center;
          font-weight: 500;
          font-size: small;
        }

        h6 {
          margin-top: 4px;
          color: $bg;
          text-align: center;
          font-size: smaller;
          font-weight: 400;
        }

        p {
          margin-top: 4px;
          color: $bg;
          align-self: center;
          font-size: x-small;
          font-weight: 400;
        }
      }
    }

    .summary {
      background-color: white;
      padding: 1em;
      border-radius: 5px;
      margin-bottom: 1em;
      position: relative;

      .data {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        gap: 1em;
        padding: 0;
      }
      .title {
        border-bottom: 1px solid $line;
        padding: 1em 0;
      }
      div {
        padding: 2em;
      }
      div > p:first-child {
        color: $primarylight;
        text-align: center;
        font-size: x-large;
        font-weight: 400;
      }
      div > p:nth-child(2) {
        color: $accent;
        text-align: center;
        font-size: medium;
        font-style: italic;
        font-weight: 400;
      }
    }
    .chart {
      background-color: white;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 5px #60606010;
      padding: 1em;

      .tp {
        display: flex;
        justify-content: space-between;

        .download {
          height: 16px;
          width: 16px;
          color: $secondary;
          cursor: pointer;
        }
        .download:hover {
          color: $primarylight;
        }

        h3 {
          color: $primary;
          font-size: medium;
          margin-bottom: 5px;
          font-weight: 520;
        }
        p {
          color: gray;
          font-size: small;
          margin-bottom: 5px;
        }
        hr {
          color: #60606010;
          margin-bottom: 10px;
        }
      }

      h3 {
        color: gray;
        font-size: medium;
        margin-bottom: 1em;
        font-weight: 600;
      }

      .save {
        position: absolute;
        right: 16px;
        top: 16px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;

        * {
          cursor: pointer;
        }
      }
    }
  }
  .part2 {
    display: grid;
    grid-template-columns: 1fr 0.3fr;
    gap: 1em;
    .perform {
      .small-title {
        border-bottom: 1px solid $line;
      }
      .items {
        display: grid;
        grid-template-columns: 0.2fr 1fr 0.3fr 0.2fr;
        background-color: white;
        border-radius: 44px;
        box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.05);
        padding: 5px;
        margin: 10px 0;
        gap: 1em;
        p {
          color: #000;
          font-size: small;
          font-weight: 400;
        }
        .duration {
          background: $line;
          text-align: center;
          color: white;
          border-radius: 15px;
          width: 72px;
          span {
            color: #000;
          }
        }
        .st {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        .badge {
          text-align: center;
          color: $secondary;
          background: $primarylighter;
          padding: 6px;
          border-radius: 15px;
          width: 100%;
        }
      }
    }
  }
  .part3 {
    height: 100%;
    width: 100%;
    .tp {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      border-bottom: 1px solid gainsboro;
      .rt {
        text-align: right;
      }
      button {
        background-color: white;
        border: 1px solid $primarylight;
        color: $primarylight;
        padding: 4px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  .part4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
    margin-top: 1em;
    .col-left {
      .bl {
        border-right: 1px solid $line;
      }
      .activity {
        display: grid;
        grid-template-columns: 0.1fr 1fr 0.1fr;
        gap: 5px;
        align-items: center;
        margin: 1em 0;

        .active {
          border: 1px solid $secondary;
          border-radius: 30px;
        }
        .date {
          text-align: center;
          background: $line;
          border-radius: 50%;
          color: white;
          height: 50px;
          width: 50px;
          padding: 2px;
        }
        .desc {
          p:first-child {
            font-size: 16px;
            font-weight: 500;
          }
          p:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
          }
        }
        .badge {
          background: #a7c6ed;
          padding: 5px;
          width: 100px;
          border-radius: 15px;
          text-align: center;
          p {
            color: $secondary;
            font-size: medium;
            font-weight: 500;
            background: $primarylighter;
          }
        }
      }
    }
    .col-right {
      .top-rt {
        border-bottom: 1px solid $line;
        padding: 14px;
        p {
          font-size: smaller;
          color: $primary;
          padding: 4px;
          width: fit-content; /* Standard property */
          width: -moz-fit-content; /* Firefox 3+, Firefox for Android 4+ */
          border-radius: 5px;
          border: 1px solid;
        }
      }
      .bold-title {
        font-size: medium;
        font-weight: 500;
      }
      p {
        color: $accent;
        font-size: smaller;
        font-weight: 400;
        padding: 6px 0;
      }
      .activity {
        font-weight: 600;
      }
    }
  }

  .part5 {
    .t-tops {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr;
      justify-content: space-between;
      align-items: center;
      .quaters {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1em;
      }
      p {
        font-size: small;
        color: $accent;
      }
      .right {
        text-align: right;
      }
    }
    .indc-performance {
      background-color: white;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 0 1em;
      margin: 0.5em 0;

      .county {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 5px;
      }
      .num {
        border: 1px solid $secondary;
        padding: 5px 10px;
        color: $secondary;
        border-radius: 5px;
        height: fit-content;
        width: 100%;
      }
      .p5 {
        padding: 5px;
      }
      .mfstats {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-left: 1px solid $accent;
        border-right: 1px solid $accent;
        padding: 10px;
        p {
          font-size: small;
          text-align: center;
        }
        .blue {
          color: $primarylight;
        }
        .red {
          color: $secondary;
        }
        .total {
          background: $primarylighter;
          padding: 5px;
          width: max-content;
          height: max-content;
          text-align: center;
          border-radius: 15px;
          margin-left: 10px;
        }
      }
      .q-stats {
        text-align: center;
        p {
          color: #ba0c2f;
          text-align: center;
          font-size: small;
          font-weight: 600;
          margin-bottom: 6px;
        }
        .gender-stats {
          text-align: -webkit-center;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 1em;
          th {
            border-bottom: 1px solid $accent;
            text-align: center;
            color: $primarylight;

            font-size: small;
            font-style: italic;
            font-weight: 300;
          }
          td {
            color: $accent;
            text-align: center;
          }
          td:first-child {
            border-right: 1px solid $accent;
          }
        }
      }
      .achievement {
        display: grid;
        grid-template-columns: 5fr 1fr;
        gap: 1em;
        i.green {
          color: $green;
        }
        i.red {
          color: $secondary;
        }
        p {
          color: $primarylight;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .top-part {
      grid-template-columns: 1fr !important;

      .topsummary {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 10px;
      }
    }
    .part {
      grid-template-columns: 1fr !important;
      .midtop-cards {
        grid-template-columns: 1fr !important;
      }
      .summary {
        .data {
          grid-template-columns: 1fr !important;
        }
      }
    }
    .part4 {
      display: block;
      .top {
        display: block;
      }
      .activity {
        display: block !important;
        border-bottom: 1px solid $line;
        padding: 6px 0;
        .date {
          width: 100%;
          color: $primary !important;
          border-radius: 10px;
          text-align: left !important;
          height: fit-content !important;
        }
       
      }
    }
  }

  @include maxwidth(tablet) {
    .top-part {
      grid-template-columns: 1fr !important;
      .topsummary {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 10px;
      }
    }

    .part {
      grid-template-columns: 1fr !important;
      .midtop-cards {
        grid-template-columns: 1fr !important;
      }
      .summary {
        .data {
          grid-template-columns: 1fr !important;
        }
      }
    }
  }
}
